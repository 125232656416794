import { DialogTitle } from "@headlessui/react";
import { useStore } from "@nanostores/react";
import * as Accordion from "@radix-ui/react-accordion";
import type { ReactElement } from "react";
import { openFeedback } from "stores/feedback";
import { $isRoadmapOpen } from "stores/roadmap";
import Button from "./ui/Button";
import Dialog from "./ui/Dialog";

export interface RoadmapEntry {
  title: string;
  description: string;
}

interface Props {
  close?: ReactElement;
  chevron?: ReactElement;
  entries?: RoadmapEntry[];
}

export default function Roadmap({ close, chevron, entries = [] }: Props) {
  const open = useStore($isRoadmapOpen);
  const onClose = () => $isRoadmapOpen.set(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-white px-6 pt-6 pb-1 overflow-y-auto">
        <div className="absolute right-0 top-0 pr-6 pt-6">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}>
            <span className="sr-only">Close</span>
            {close}
          </button>
        </div>
        <div className="flex items-start overflow-y-auto">
          <div className="text-left w-full overflow-y-auto">
            <DialogTitle
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900">
              Reviuer roadmap
            </DialogTitle>
            <div className="mt-1 mb-5">
              <p className="text-sm text-gray-600">
                This is what we are going to build with your help.
              </p>
            </div>
            <Accordion.Root
              className="overflow-y-auto max-h-96 sm:max-h-[476px] space-y-6"
              type="multiple"
              defaultValue={[entries[0].title]}>
              {entries.map(({ title, description }) => (
                <Accordion.Item
                  value={title}
                  key={title}
                  className="border-b pb-6">
                  <Accordion.Header>
                    <Accordion.Trigger className="group w-full flex justify-between">
                      <span className="font-medium text-gray-900">{title}</span>
                      {chevron}
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden">
                    <div className="pt-2 text-gray-600 max-w-xs sm:max-w-full">
                      {description}
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 text-gray-900 font-semibold px-6 pb-6 pt-4 flex flex-col sm:flex-row gap-x-6 items-start">
        Any specific need or feedback?
        <Button
          style="muted"
          block
          className="text-brand-700 underline text-start sm:w-auto"
          onClick={openFeedback}>
          Go here
        </Button>
      </div>
    </Dialog>
  );
}
